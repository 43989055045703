import type { Pinia } from 'pinia'
import { getCountryCodeFromCookie } from 'lib/cookie'
import { getDefaultMarket, getStorefrontByCountryCode, isMarketInStorefront } from 'lib/storefronts'
import { stripStorefrontCodeFromUrl } from 'lib/url/stripStorefrontCode'

/*
 * Inject storefront information into nuxt and handle
 * unset cookies
 */
export default defineNuxtPlugin({
  name: 'storefront',

  async setup(nuxtApp) {
    const ssrContext = nuxtApp.ssrContext
    const $pinia = nuxtApp.$pinia as Pinia

    let currentCountryCode = getCountryCodeFromCookie(ssrContext)
    const { storefrontCode: storefrontCodeOrDefault } = stripStorefrontCodeFromUrl(useRoute().path)
    const storefrontCode = storefrontCodeOrDefault === '_default' ? undefined : storefrontCodeOrDefault

    if (!currentCountryCode || (storefrontCode && !isMarketInStorefront(currentCountryCode, storefrontCode)))
      currentCountryCode = getDefaultMarket(storefrontCode ?? undefined).countryCode

    const storefrontStore = useStorefrontStore($pinia)
    let currentStorefront = getStorefrontByCountryCode(currentCountryCode)

    // Language Split Test
    const englishLanguageTestCookie = useSplitTest('ALL_01_DE_ALL')
    const forceEnglishLanguage = currentStorefront.storefrontCode === 'de' && englishLanguageTestCookie.value === 'variant2'

    if (forceEnglishLanguage) {
      currentStorefront = {
        ...currentStorefront,
        contentKey: '_default',
        contentLocale: 'en',
        tenantKey: 'EU',
      }
    }
    // End Language Split Test

    storefrontStore.setCurrentStorefront(currentStorefront)
    storefrontStore.setCurrentMarket(currentCountryCode)

    // TODO: Remove the use of injection since we provide the currentStorefront in the pinia store
    return {
      provide: {
        currentStorefront,
      },
    }
  },
})
