import type { PossibleEnvironment } from 'types/environment'
import type { Storefront } from 'types/models/storefront'
import { generatedStorefronts } from 'data/storefronts.gen'
import { isValidEnvironment } from 'types/generic/geographic'

/**
 * Returns all storefronts for a given environment
 */
export function getStorefronts(environment: PossibleEnvironment): Storefront[] {
  if (!isValidEnvironment(environment))
    throw new EvalError(`Invalid environment passed: ${environment}`)

  return generatedStorefronts.environments[environment]
}
