<script lang="ts" setup>
const props = defineProps<{
  showRestartButton?: boolean
  config?: Partial<BouncingBallsConfig>
}>()

const canvasEl = ref<HTMLCanvasElement>()
const config = computed<Partial<BouncingBallsConfig>>(() => props.config || {})
const { reset, start, restart } = useBouncingBalls(canvasEl, config)

useIntersectionObserver(canvasEl, ([e]) => {
  if (e.isIntersecting)
    start()
  else
    reset()
})
</script>

<template>
  <ClientOnly>
    <canvas
      ref="canvasEl"
      class="canvas"
    />
    <button
      v-if="showRestartButton"
      @click="restart"
    >
      RESTART
    </button>
  </ClientOnly>
</template>

<style lang="scss" scoped>
canvas {
  width: 100%;
  height: 100%;

  @media (prefers-reduced-motion) {
    display: none;
  }
}

button {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
