interface SplitTest {
  active: boolean
  id: string
  name: string
  variants: string[]
  weights: number[]
  defaultVariant: string // If not in trafficPercentage, this is the default variant

  startDate?: string
  endDate?: string
  audience?: string
  trafficPercentage?: number
  disableISRForPages?: string[] // Glob patterns, example: '/storefrontsGlob/*(happyblog|about-us|faq|shipping|happynews)/**'
  disableISRForStorefronts?: string[]
}

export const splitTests: SplitTest[] = [
  {
    name: 'Colorful top USP Banner',
    id: 'ALL_02_DE_ALL',
    variants: ['variant1', 'variant2'],
    weights: [50, 50],
    defaultVariant: 'variant2',
    active: true,
  },
  // {
  //   name: 'Alternate Landing Pages',
  //   id: 'LP_02_ALL_ALL',
  //   variants: ['variant1', 'variant2'],
  //   weights: [20, 80],
  //   defaultVariant: 'variant2',

  //   disableISRForPages: [`/${storefrontsGlob}/`, `/${storefrontsGlob}`],
  // },
]

const HALF_A_YEAR_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 180
export const splitTestCookieOptions = {
  path: '/',
  maxAge: HALF_A_YEAR_IN_MILLISECONDS,
  secure: true,
  sameSite: 'strict' as const,
}

export function getSplitTestCookieOptions(test: SplitTest) {
  const maxAge: number = test.endDate ? new Date(test.endDate).getTime() - new Date().getTime() : splitTestCookieOptions.maxAge

  return {
    ...splitTestCookieOptions,
    maxAge,
  }
}

export const getSplitTestCookieString = (testId: string) => `experiment-${testId}`

export function getActiveSplitTests(geoCountry: string): SplitTest[] {
  return splitTests.filter((test) => {
    const currentDate = new Date().toISOString()
    if (test.startDate && test.startDate > currentDate)
      return false

    if (test.endDate && test.endDate < currentDate)
      return false

    if (test.audience && test.audience !== geoCountry)
      return false

    return true
  })
}

export function getRandomVariant(test: SplitTest) {
  const randomValue = Math.random() * 100
  let sum = 0

  for (let i = 0; i < test.weights.length; i++) {
    sum += test.weights[i]

    if (randomValue < sum)
      return test.variants[i]
  }

  return test.defaultVariant
}

export function getVariant(test: SplitTest) {
  const isControlGroup = test.trafficPercentage ? Math.random() * 100 > test.trafficPercentage : false
  return isControlGroup ? test.defaultVariant : getRandomVariant(test)
}

export function getAllISRDisabledPages(): string[] {
  const pages = new Set<string>()
  for (const test of splitTests) {
    if (test.disableISRForPages) {
      for (const page of test.disableISRForPages)
        pages.add(page)
    }
  }

  return Array.from(pages)
}

export function getAllISRDisabledStorefronts(): string[] {
  const storefronts = new Set<string>()
  for (const test of splitTests) {
    if (test.disableISRForStorefronts) {
      for (const storefront of test.disableISRForStorefronts)
        storefronts.add(storefront)
    }
  }

  return Array.from(storefronts)
}
