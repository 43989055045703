import type { Environment } from 'types/environment'
import type { BannerResponse } from 'types/storyblok'

export function getContentByCountryCode(content: BannerResponse | undefined, countryCode: Environment.CountryCode, storeCode: Environment.StorefrontCode) {
  // Language Split Test
  const englishLanguageTestCookie = useSplitTest('ALL_01_DE_ALL')
  const forceEnglishLanguage = storeCode === 'de' && englishLanguageTestCookie.value === 'variant2'

  let countryCodeOverride = countryCode.toLowerCase() as Environment.StorefrontCode
  let storeCodeOverride = storeCode
  if (forceEnglishLanguage) {
    countryCodeOverride = 'eu'
    storeCodeOverride = 'eu'
  }
  // End Language Split Test

  const contentByCountryCode = content?.[countryCodeOverride]
  const contentByStoreCode = content?.[storeCodeOverride]

  return contentByCountryCode || contentByStoreCode
}
