<script setup lang="ts" inherit-attrs="false">
type IconAligment = 'close' | 'back' | 'forward' | 'clouds' | 'heart' | 'happy' | 'chevron' | 'plus' | ''

interface Props {
  iconLeft?: IconAligment
  iconRight?: IconAligment
  loading?: boolean
  design?: 'jazzy' | 'simple' | 'pill' | 'no-border' | 'radius' | 'radius-without-border'
  size?: 'tiny' | 'small' | 'medium' | 'large'
  nativeType?: Button.NativeType
  tag?: Button.Tag
  uppercase?: boolean
  bold?: boolean
  backgroundColor?: string
  color?: string
  hoverColor?: string
}

const props = withDefaults(defineProps<Props>(), {
  iconLeft: '',
  iconRight: '',
  loading: false,
  design: 'jazzy',
  size: 'medium',
  nativeType: 'button',
  tag: 'button',
  uppercase: false,
  backgroundColor: 'var(--white)',
  color: 'var(--black)',
  hoverColor: 'var(--bg-color)',
})

const state = reactive({
  hover: false,
})

const attributes = useAttrs()

const computedTag = computed(() => {
  if (props.tag === 'nuxt-link')
    return resolveComponent('NuxtLink')
  if (attributes.disabled !== undefined && attributes.disabled !== 'false')
    return 'button'

  return props.tag
})
</script>

<template>
  <component
    :is="computedTag"
    v-bind="$attrs"
    :type="nativeType"
    class="button"
    :class="[
      `size-${size}`,
      {
        'is-loading': loading,
        'is-jazzy': design === 'jazzy',
        'is-pill': design === 'pill',
        'no-border': design === 'no-border',
        'has-radius': design === 'radius',
        'has-radius-without-border': design === 'radius-without-border',
        'uppercase': uppercase,
        'bold': bold,
      },
    ]"
    :style="{ '--bg-color': backgroundColor, '--text-color': color, '--hover-color': hoverColor }"
    @mouseover="state.hover = true"
    @mouseout="state.hover = false"
  >
    <IconBasic
      v-if="iconLeft"
      :filename="iconLeft"
    />
    <span v-if="$slots.default">
      <slot />
    </span>

    <IconBasic
      v-if="iconRight"
      :filename="iconRight"
    />
  </component>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.button {
  display: flex;
  padding: 0 1rem;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: background-color ease-in-out 0.2s;
  border: 1px solid var(--black);
  text-align: center;
  line-height: 1;
  font-family: var(--happy-font);
  background-color: var(--bg-color);
  color: var(--text-color);
  cursor: pointer;
  &:hover {
    background-color: var(--hover-color);
  }
}

.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: 700;
}
:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.is-jazzy::after {
  bottom: 2px;
  content: '';
  left: -0.2rem;
  right: -0.2rem;
  display: block;
  position: absolute;
  border-style: solid;
  border-color: transparent;
  border-top-color: var(--black);
  transform: translateY(calc(100% + 0.2rem));
}

.size-tiny {
  padding: 0.3rem 0.6rem;
  font-size: 1.2rem;
  min-height: 1.8rem;
  :deep(.icon) {
    width: 0.7rem;
    height: 0.7rem;
  }
  &.is-jazzy {
    margin-bottom: 0.2rem;
  }
  &.is-jazzy::after {
    border-width: 0.2rem;
  }
}

.size-small {
  min-height: 2.8rem;
  padding: 0.5rem 1rem;
  :deep(.icon) {
    width: 1rem;
    height: 1rem;
  }
  &.is-jazzy {
    margin-bottom: 0.3rem;
  }
  &.is-jazzy::after {
    border-width: 0.3rem;
  }
}

.size-medium {
  font-size: 1.4rem;
  min-height: 4rem;
  padding: 0.8rem 1.6rem;
  :deep(.icon) {
    width: 2rem;
    height: 2rem;
  }
  &.is-jazzy {
    margin-bottom: 0.5rem;
  }

  &.is-jazzy::after {
    border-width: 0.5rem;
  }
}

.size-large {
  font-size: 3rem;
  padding: 1.7rem 3.4rem;
  min-height: 6.4rem;
  :deep(.icon) {
    width: 2.4rem;
    height: 2.4rem;
  }
  &.is-jazzy {
    margin-bottom: 0.7rem;
  }
  &.is-jazzy::after {
    border-width: 0.7rem;
  }
}

.is-pill {
  min-height: unset;
  border-radius: 3rem;
}

.no-border {
  border: none;
}

.has-radius {
  border-radius: 2px;
}

.has-radius-without-border {
  border: none;
  border-radius: 2px;
}

.icon + span {
  margin-left: 1rem;
}

.icon + .icon {
  margin-left: 1rem;
}

span + .icon {
  margin-left: 1rem;
}

.normal {
  font-style: normal;
}

.price {
  margin-right: 2rem;
}

@media (min-width: $tablet) {
  .size-tiny.is-jazzy:not(:disabled):hover,
  .size-tiny.is-jazzy:not(:disabled):focus,
  .size-small.is-jazzy:not(:disabled):hover,
  .size-small.is-jazzy:not(:disabled):focus {
    transform: translateY(0.1rem);
    &::after {
      bottom: 0.1rem;
    }
  }

  .size-medium.is-jazzy:not(:disabled):hover,
  .size-medium.is-jazzy:not(:disabled):focus {
    transform: translateY(0.2rem);
    &::after {
      bottom: 0.2rem;
    }
  }

  .size-large.is-jazzy:not(:disabled):hover,
  .size-large.is-jazzy:not(:disabled):focus {
    transform: translateY(0.3rem);
    &::after {
      bottom: 0.3rem;
    }
  }
}
</style>
