import type { Environment } from 'types/environment'

import type * as Helpers from 'types/helpers'
import {
  cleanDoubleSlashes,
  parseURL as parseURLUfo,
  withoutLeadingSlash,
  withoutTrailingSlash,
  withQuery,
} from 'ufo'
import { stripStorefrontCodeFromUrl } from './stripStorefrontCode'

export function parseURL(url: string): URL {
  try {
    const withHttps = url.replace(/^(https?)?:?(\/{0,2})/, 'https://')
    return new URL(withHttps)
  }
  catch (e) {
    const {
      public: { baseUrlClient, vercelUrl },
    } = useRuntimeConfig()
    const baseUrl = baseUrlClient === '/' && vercelUrl ? `https://${vercelUrl}` : `${baseUrlClient}`
    console.warn(e)
    return new URL('', baseUrl)
  }
}

export function sluggify(string: string): string {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}

export function replaceStorefrontcodeInUrl(storefrontCode: Environment.StorefrontCode): string {
  return `${window.location.origin}/${storefrontCode}${
    stripStorefrontCodeFromUrl(window.location.pathname).urlWithoutStorefrontCode
  }`
}

export const languageCodes = ['en-GB', 'nl-NL', 'de-DE']

export function stripLanguageCodeFromUrl(url: string) {
  const urlWithoutLanguageCode = url.replace(new RegExp(`(${languageCodes.join('|')})`, 'i'), '')

  return cleanDoubleSlashes(urlWithoutLanguageCode)
}

function stripDefaultWord(url: string) {
  const stringToReplace = '/_default/'
  const defaultRegExp = new RegExp(stringToReplace)

  if (defaultRegExp.test(url))
    return url.replace(stringToReplace, '/')

  return url
}

export const url: Helpers.UrlHelper = (path, parameters = {}) => {
  if (path.startsWith('www'))
    path = `https://${path}`

  const url = parseURLUfo(path)

  if (url.protocol || url.host)
    return path

  const storefrontCode = useStorefrontStore().currentStorefrontCode
  const pathWithoutLanguageCode = stripLanguageCodeFromUrl(path)
  const { urlWithoutStorefrontCode } = stripStorefrontCodeFromUrl(pathWithoutLanguageCode)

  const restOfUrl
    = urlWithoutStorefrontCode === '/'
      ? ''
      : withoutLeadingSlash(withoutTrailingSlash(urlWithoutStorefrontCode))
  const cleanedResult = cleanDoubleSlashes(`/${storefrontCode}/${restOfUrl}`)

  const result = stripDefaultWord(cleanedResult)

  return withQuery(result, parameters)
}
