import type { Tenant } from 'helpers/tenant'
import type { Environment } from 'types/environment'

import type { ProductBadge, ProductVariant } from 'types/models/product'
import type { WidgetProduct } from 'types/vendors/xo'
import { getDefaultMarket } from 'lib/storefronts'
import { productBadgeNames } from 'types/models/product'

function getDiscountPercentage(
  variant: ProductVariant,
  currencyCode: Environment.Currency['code'],
) {
  const currencyDiscount = variant.currencies?.[currencyCode]?.discountPercentage
  const fallbackDiscount = variant.discount.percentage

  return currencyDiscount || fallbackDiscount
}

/**
 * Check whether pricing is consistent across product variants
 */
function isDiscountConsistent(
  variants: ProductVariant[],
  currencyCode: Environment.Currency['code'],
) {
  if (variants.length <= 0)
    return false

  const baseDiscountPercentage = getDiscountPercentage(variants[0]!, currencyCode)

  return variants.every(
    variant => getDiscountPercentage(variant, currencyCode) === baseDiscountPercentage,
  )
}

function getDiscountBadgeValue(
  variants: ProductVariant[],
  currencyCode: Environment.Currency['code'],
) {
  if (variants.length <= 0)
    return null
  if (!isDiscountConsistent(variants, currencyCode))
    return null

  return getDiscountPercentage(variants[0]!, currencyCode)
}

export function getProductBadges(
  widgetProduct: WidgetProduct,
  variants: ProductVariant[],
  tenant: Tenant,
): ProductBadge[] {
  const badges: ProductBadge[] = []
  const market = getDefaultMarket(tenant.storefrontCode)
  const currencyCode = market.currency.code
  const variantsWithStock = variants.filter(variant => variant.stockStatus !== 'no-stock')

  // check for sale tag
  if (variantsWithStock.length > 0) {
    const discountBadgeValue = getDiscountBadgeValue(variantsWithStock, currencyCode)

    if (discountBadgeValue) {
      badges.push({
        name: 'discount',
        value: discountBadgeValue,
      })
    }
  }

  // check for other badges
  productBadgeNames.forEach((badgeName) => {
    if (widgetProduct.categories.includes(`badge-${badgeName}`))
      badges.push({ name: badgeName })
  })
  return badges
}
