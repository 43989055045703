import type { ISbRichtext, ISbSchema } from 'storyblok-js-client'
// clone() is the only option to woraround the cloneDeep issue at the moment
// Source: https://github.com/storyblok/storyblok-vue/issues/445#issuecomment-1751727041
import clone from 'clone'
import { RichtextSchema, SbHelpers } from 'storyblok-js-client'
import { url } from '~/lib/url'

export const classes = {
  paragraph: 'rich-text--paragraph',
  link: 'rich-text--link',
  bulletList: 'rich-text--bullet-list',
}

export function normalizeRichtext(richtext: any): ISbRichtext {
  if (!richtext) {
    return {
      type: 'doc',
      content: [],
    }
  }

  if (typeof richtext === 'string') {
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: richtext,
            },
          ],
        },
      ],
    }
  }

  return richtext
}

export function formatUrl(nodeAttrs: any) {
  if (!nodeAttrs)
    return {}

  const attrs = {
    ...nodeAttrs,
    class: classes.link,
    href: nodeAttrs?.href ? nodeAttrs?.story?.full_slug || nodeAttrs?.href : '',
  }
  const { linktype = 'url' } = nodeAttrs
  delete attrs.linktype
  const isEmailLinkType = (type: string) => type === 'email'
  const escapeHTML = new SbHelpers().escapeHTML

  if (attrs.href)
    attrs.href = escapeHTML(attrs.href || '')

  if (isEmailLinkType(linktype))
    attrs.href = `mailto:${attrs.href}`
  else
    attrs.href = url(attrs.href)

  if (attrs.anchor) {
    attrs.href = `${attrs.href}#${attrs.anchor}`
    delete attrs.anchor
  }

  return attrs
}

/**
 * @see {@link https://github.com/storyblok/storyblok-nuxt#rendering-rich-text}
 */
export function getSchema() {
  function linkNodeFunction(node: any) {
    if (!node.attrs) {
      return {
        tag: '',
      }
    }

    const attrs = formatUrl(node.attrs)

    return {
      tag: [
        {
          tag: 'a',
          attrs,
        },
      ],
    }
  }

  function paragraphNodeFunction(node: any) {
    const attrs = { ...node.attrs, class: classes.paragraph }
    return {
      tag: [
        {
          tag: 'p',
          attrs,
        },
      ],
    }
  }

  function bulletListNodeFunction(node: any) {
    const attrs = { ...node.attrs, class: classes.bulletList }
    return {
      tag: [
        {
          tag: 'ul',
          attrs,
        },
      ],
    }
  }
  // clone() is the only option to woraround the cloneDeep issue at the moment
  // Source: https://github.com/storyblok/storyblok-vue/issues/445#issuecomment-1751727041
  const mySchema: ISbSchema = clone(RichtextSchema) // you can make a copy of the default RichTextSchema

  // ... and edit the nodes and marks, or add your own.
  // Check the base RichTextSchema source here https://github.com/storyblok/storyblok-js-client/blob/v4/source/schema.js
  mySchema.marks.link = linkNodeFunction
  mySchema.nodes.paragraph = paragraphNodeFunction
  mySchema.nodes.bullet_list = bulletListNodeFunction

  return mySchema
}
