import type Storyblok from '~/types/vendors/storyblok'
import { FilterXSS } from 'xss'
import { getSchema, classes as richtextClasses } from '~/utils/storyblok/richtext'

type inputDataType = undefined | Storyblok.Richtext

export function useRichtext(data: MaybeRef<inputDataType>) {
  const schema = getSchema()
  const myxss = new FilterXSS({
    /**
     * @see https://github.com/leizongmin/js-xss?tab=readme-ov-file#customize-the-handler-function-for-attributes-of-matched-tags
     */
    onTagAttr: (_, name, value) => {
      if (name === 'class') {
        const values = value.split(' ')
        const sanitizedValues = values.filter(c => Object.values(richtextClasses).includes(c)).join(' ')

        return sanitizedValues ? `${name}="${sanitizedValues}"` : ''
      }
    },
  })

  const content = computed(() => myxss.process(renderRichText(toValue(data), {
    schema,
  })))

  return content
}
