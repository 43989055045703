import type {
  RawRichtext,
  Story,
  StoryblokRequestParameters,
} from '~/types/storyblok'
import type { LinkBlockSchema } from '~/types/storyblok/link-block'
import type { RichTextSchema } from '~/types/storyblok/rich-text'
import type { CustomColor, Palette } from '~/types/storyblok/shared'
import type Storyblok from '~/types/vendors/storyblok'
import {
  $URL,
  cleanDoubleSlashes,
  withoutLeadingSlash,
  withoutTrailingSlash,
  withTrailingSlash,
} from 'ufo'
import { processImage } from '~/lib/images/storyblok'
import { parseURL } from '~/lib/url'

import { stripStorefrontCodeFromUrl } from './url/stripStorefrontCode'

// Legacy mixin: TextColorStyling.js
export function textColorStyling(story: RichTextSchema | LinkBlockSchema) {
  return story?.color?.color
    ? {
        'color': story.color.color,
        'border-color': story.color.color,
      }
    : { 'color': 'black', 'border-color': 'black' }
}

// Legacy mixin: BackgroundStyling.js
export function backgroundStyling(story: Story) {
  if (!story)
    return ''

  const gradientStart = story.gradient_start
  const gradientMid = story.gradient_mid
  const gradientEnd = story.gradient_end
  const backgroundImage = story.background_image

  switch (true) {
    case Boolean(backgroundImage):
      return {
        backgroundImage: `url('${processImage(parseURL(backgroundImage || ''), { fallback: true })}')`,
      }
    case Boolean(gradientStart) && Boolean(gradientMid) && Boolean(gradientEnd):
      return {
        background: `linear-gradient(${gradientStart}, ${gradientMid}, ${gradientEnd})`,
      }
    case Boolean(gradientStart) && Boolean(gradientEnd):
      return {
        background: `linear-gradient(${gradientStart}, ${gradientEnd})`,
      }
    default:
      return ''
  }
}

export function getStoryApiUrlParams(
  url: string,
  {
    contentKey,
    cache = true,
    version,
    language,
    contentType,
    resolveLinks,
    resolveRelations,
  }: { contentKey: string, cache?: boolean, version?: 'draft' | 'published', language?: string, contentType?: string, resolveLinks?: 'url', resolveRelations?: string[] },
) {
  const { pathname, searchParams } = new $URL(url)

  const { urlWithoutStorefrontCode, storefrontCode } = stripStorefrontCodeFromUrl(pathname)

  const restOfPath = withoutLeadingSlash(urlWithoutStorefrontCode)
  const defaultPath = cleanDoubleSlashes(`_default/${restOfPath}`)
  const contentPath = cleanDoubleSlashes(`${contentKey}/${restOfPath}`)

  const contentSlug = withoutTrailingSlash(contentPath)
  const contentSlugWithTrailingSlash = withTrailingSlash(contentPath)
  const defaultSlug = withoutTrailingSlash(defaultPath)
  const defaultSlugWithTrailingSlash = withTrailingSlash(defaultPath)

  const contentSlugs = storefrontCode === '_default'
    ? []
    : [
        contentSlug,
        contentSlugWithTrailingSlash,
      ]
  const defaultSlugs = [
    defaultSlug,
    defaultSlugWithTrailingSlash,
  ]

  const slugs = [
    ...contentSlugs,
    ...defaultSlugs,
  ]
    .map(cleanDoubleSlashes)
    .join(',')

  const params: StoryblokRequestParameters = {
    by_slugs: slugs,
  }

  if (!cache)
    params._t = Date.now().toString()

  const fromRelease = searchParams.get('_storyblok_release')
  if (fromRelease)
    params.from_release = fromRelease
  if (version)
    params.version = version
  if (language && language !== 'default')
    params.language = language
  if (contentType)
    params.content_type = contentType
  if (resolveLinks)
    params.resolve_links = resolveLinks
  if (resolveRelations)
    params.resolve_relations = resolveRelations

  return params
}

/**
 * @deprecated Use `normalizeRichtext` instead followed by the `renderRichtext` function inside the Vue component from `utils/storyblok/richtext.ts` to render the normalized richtext.
 */
export function normalizeRichtextDeprecated(rawRichtext: RawRichtext | Storyblok.Richtext) {
  try {
    const richtextContent = rawRichtext.content?.[0]?.content

    if (!richtextContent)
      return undefined

    const items: Storyblok.NormalizedRichtextFragment[] = richtextContent.map((item) => {
      const markAttr = item.marks ? item.marks[0]?.attrs : null
      return {
        text: item.text?.trim() || '',
        type: markAttr ? 'link' : 'text',
        attrs: markAttr
          ? {
              href: markAttr.href,
              target: markAttr.target,
            }
          : undefined,
      }
    })
    return items
  }
  catch (error) {
    console.error(error)
    throw new Error('Error while normalizing richtext')
  }
}

export function normalizeBackgroundColor(bgColor?: Palette | CustomColor, noDefault?: boolean): string | undefined {
  if (bgColor?.component === 'palette')
    return bgColor.palette_color
  else if (bgColor?.component === 'custom-color')
    return bgColor.custom_color
  else if (noDefault)
    return undefined
  else
    return '#FE96B5'
}
