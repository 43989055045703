<script lang="ts" setup>
interface Props {
  color: string
  text: string
}

const props = defineProps<Props>()
</script>

<template>
  <div class="wrapper">
    <IconBasic
      filename="error"
      :color="props.color"
      class="icon"
    />
    <p class="error">
      {{ props.text }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
    margin-top: 2px;
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
}

.icon {
    min-height: 1.8rem;
    min-width: 1.8rem;
}

.error {
    font-size: 1.2rem;
    margin-top: 1.1rem;
    color: v-bind(color);
}
</style>
