<script setup lang="ts">
const modalStore = useModalStore()
const { showCountrySwitcher } = storeToRefs(modalStore)
</script>

<template>
  <div class="container">
    <div class="item">
      <ClientOnly>
        <ButtonCountrySwitcher
          :show-text="true"
          @click="showCountrySwitcher = true"
        />
      </ClientOnly>
    </div>
    <SocialMedia class="item" />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.container {
  background-color: var(--gray);
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2rem 1.6rem;

  @media (min-width: $tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-top: 6.4rem;
    gap: 0;
    padding: 2rem 1rem 2.8rem;
  }
}
.item {
  @media (min-width: $tablet) {
    width: 30%;
    padding: 0 1.6rem;
  }
}
</style>
