import type { Environment } from 'types/environment'
import type { StoryblokContent } from '~/types/storyblok'
import type Storyblok from '~/types/vendors/storyblok'
import { zodUnions } from 'types/environment'

export type marketCode = Lowercase<Environment.CountryCode>

export type AllMarketsContent = StoryblokContent & {
  [_key in marketCode]: Storyblok.Text
} & {
  component: 'all-markets'
}

export type AllMarkets = {
  [_key in marketCode]: string
}

const markets = zodUnions.countryCodes

export function normalizeAllMarkets(content: AllMarketsContent): AllMarkets {
  const normalized: Partial<AllMarkets> = {}

  for (const maybeKey of Object.keys(content)) {
    const exists = markets.safeParse(maybeKey.toUpperCase())

    if (exists.success) {
      const key = exists.data.toLowerCase() as marketCode
      normalized[key] = content[key]
    }
  }

  return normalized as AllMarkets
}
