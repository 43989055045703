import type { Environment, PossibleEnvironment } from 'types/environment'
import type { Market } from 'types/models/market'

import type { Storefront } from 'types/models/storefront'

import { MARKET_COOKIE } from 'configuration/global.configuration'

import { generatedStorefronts } from 'data/storefronts.gen'
import { getCookie } from 'lib/cookie'
import { isValidEnvironmentCountryCode } from 'types/guards/storefront'
import { replaceStorefrontcodeInUrl } from './url'

/**
 * TODO: Clean up and split into helpers and routing functions
 */

export function getContentKeyFromStorefrontCode(storefrontCode: Environment.StorefrontCode) {
  return generatedStorefronts.environments.production.find(
    ({ storefrontCode: code }) => code === storefrontCode,
  )!.contentKey
}

function getCurrentEnvironment() {
  try {
    const env = process.env.ENVIRONMENT
    if (!env) {
      const runtimeConfig = useRuntimeConfig()
      return runtimeConfig.public.environment
    }

    return env
  }
  catch (error) {
    return 'production'
  }
}

export function getGeneratedStorefronts(env?: PossibleEnvironment) {
  return generatedStorefronts.environments[env || getCurrentEnvironment() as PossibleEnvironment]
}

export function getAllMarkets(env?: PossibleEnvironment) {
  return getGeneratedStorefronts(env).reduce((acc, storefront) => {
    return [...acc, ...storefront.markets]
  }, [] as Market[])
}

export function getMarket(code: Environment.CountryCode) {
  return getAllMarkets().find(({ countryCode }) => countryCode === code)!
}

export function isMarketInStorefront(countryCode: Environment.CountryCode, storefrontCode: Environment.StorefrontCode) {
  return getMarket(countryCode).storefrontCode === storefrontCode
}

export function getStorefront(code: Environment.StorefrontCode) {
  return getGeneratedStorefronts().find(({ storefrontCode }) => storefrontCode === code)!
}

export function getStorefrontByCountryCode(countryCode: Environment.CountryCode) {
  return getGeneratedStorefronts().find(storefront =>
    storefront.markets.find(market => market.countryCode === countryCode),
  )!
}

export function getDefaultMarket(storefrontCode?: Environment.StorefrontCode) {
  return storefrontCode
    ? (getStorefront(storefrontCode).markets[0] as Market)
    : ((getGeneratedStorefronts()[0] as Storefront).markets[0] as Market)
}

function hardRedirectToHomepage(market: Market) {
  const url = new URL(`${window.location.origin}/${market.storefrontCode}`)
  window.location.href = url.href
}

function hardRedirectAndRetainPath(market: Market) {
  const url = new URL(replaceStorefrontcodeInUrl(market.storefrontCode))
  window.location.href = url.href
}

export async function handleCountrySwitchRouting(prevMarket: Market, newMarket: Market) {
  const router = useRouter()
  const isSameWarehouse = prevMarket.warehouse.region === newMarket.warehouse.region
  const isSameStorefront = prevMarket.storefrontCode === newMarket.storefrontCode
  const isMenuRoute = router.currentRoute.value.name === 'storefront-menu'
  const isIndexRoute = router.currentRoute.value.name === 'index'

  if (!isSameWarehouse || isIndexRoute) {
    hardRedirectToHomepage(newMarket)
    return false
  }

  if (!isSameStorefront) {
    isMenuRoute ? hardRedirectToHomepage(newMarket) : hardRedirectAndRetainPath(newMarket)
    return false
  }

  const storefrontStore = useStorefrontStore()
  const cartStore = useCartStore()
  const checkoutStore = useCheckoutStore()

  storefrontStore.setCurrentMarket(newMarket.countryCode)
  // Update Brink Cart
  await cartStore.updateCountryCode(newMarket.countryCode)
  checkoutStore.updateCountryCode(newMarket.countryCode)

  return true
}

export async function checkAndUpdateCountryCode() {
  const storefrontStore = useStorefrontStore()
  const newCountryCode = getCookie(MARKET_COOKIE)?.toUpperCase()
  const currentCountryCode = storefrontStore.currentMarketCountryCode

  if (
    newCountryCode
    && newCountryCode !== currentCountryCode
    && isValidEnvironmentCountryCode(newCountryCode)
  ) {
    const newMarket = getMarket(newCountryCode)
    const prevMarket = getMarket(currentCountryCode)

    if (!newMarket)
      return
    await handleCountrySwitchRouting(prevMarket, newMarket)
  }
}

export function getFocusMarketStorefrontCodes() {
  return getGeneratedStorefronts()
    .filter(storefront => storefront.isFocusMarket)
    .map(storefront => storefront.storefrontCode as string)
}

export function getUmbrellaMarketStorefrontCodes() {
  return getGeneratedStorefronts()
    .filter(storefront => !storefront.isFocusMarket)
    .map(storefront => storefront.storefrontCode as string)
}
