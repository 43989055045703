import type { Environment } from './environment'
import type Storyblok from './vendors/storyblok'
import type { TableHead, TableRow } from './vendors/storyblok'

// Full object: https://www.storyblok.com/docs/api/content-delivery#core-resources/stories/the-story-object
export interface StoryblokContent {
  _uid: string
  component: string
  _editable: string
}

export type Story<Content = any> = Storyblok.Story<Content>

// Full object: https://www.storyblok.com/docs/api/management#core-resources/stories/the-story-object
export interface StoryMapi {
  id: number
  uuid: string
  full_slug: string
  published_at: string
  published: boolean
  content_type: string
  // There is more...
}

export type SupportedStoryblokSlugs =
  | 'banner'
  | 'checkout-banner'
  | 'submenu'

const supportedComponents = [
  'banner',
  'navigation',
  'newsletter',
  'checkout-message-banner',
] as const

export function isSupportedComponent(name: any): name is (typeof supportedComponents)[number] {
  return name && supportedComponents.includes(name)
}

export interface StoryblokSiteMapLink {
  fullSlug: string
  publishedAt: string
}

export interface Datasource {
  name: string
  value: string
}

export interface MultiStoryResponse<T = { [key: string]: string | number | boolean }> {
  stories: Story<T>[]
}

export interface RawRichtext {
  type: string
  content: RawRichtextContent[]
}
interface RawRichtextContent {
  type: string
  content: RawRichtextContentItem[]
}
interface RawRichtextContentItem {
  text: string
  type: string
  marks?: Mark[]
}
interface Mark {
  type: string
  attrs: MarkAttrs
}
interface MarkAttrs {
  href: string
  uuid: string
  anchor?: any
  target: string
  linktype: string
}

interface Link {
  url: string
}

export type SocialLink = Link & {
  network: 'twitter' | 'pinterest' | 'facebook' | 'instagram' | 'tiktok'
}

export type NavigationLink = Link & {
  text: string
  large: boolean
  external: boolean
}

export interface UILink {
  text: string
  _uid: string
}

export interface StoryblokAsset {
  id: number
  alt: string
  name: string
  focus: null
  title: string
  filename: string
  copyright: string
  fieldtype: 'asset'
}

export interface ColorPickerStory {
  _uid: string
  color: string
  plugin: string
}

export interface TextStory {
  _uid: string
  html: string
  plugin: string
  original: string
}

// TODO: distinguish types of links as different interfaces (currently it's a total mess)

export interface LinkStory {
  id: string
  cached_url: string
  fieldtype: string
  linktype: 'url' | 'story'
  linkType: 'url' | 'story' | 'internal'
  url: string
  internalLinkType: string
  slug: string
}

type BannerContent = { [storeCode in Environment.StorefrontCode]: string }

export interface BannerResponse extends BannerContent {
  uid: string
  component: 'banner'
  link: {
    url: string
    cached_url: string
  }
  linkUs: {
    url: string
    cached_url: string
  }
}

export interface CheckoutBannerResponse {
  _uid: string
  message: string
  component: 'checkout-message-banner'
  _editable: string
}
export interface StoreSettingStory {
  _uid: string
  component: 'store_settings'
  findify_api_key: string
  og_image_global?: string
  store: string
}

export interface SettingsStory extends Story {
  alternates: any[]
  content: {
    component: string
    findify_default_dashboard: string
    global_og_image: string
    pdp_recommendations_limit: string | number
    pdp_recommendations_limit_for_modal: string | number
    pdp_recommendations_title: string
    pdp_recommendations_type: string
    pdp_recommendations_type_for_modal: string
    store_settings: StoreSettingStory[]
  }
  name: 'Site Settings'
  meta_data: any
}

export interface TablePlugin {
  fieldtype: 'table'
  thead: TableHead[]
  tbody: TableRow[]
}
export interface StoryParameter {
  title?: string
  description?: string
  image?: string
  metaRobots?: string[]
  path?: string
}

export interface LinkWrapperSchema extends Story {
  link: {
    linktype: string
    internalLinkType: string
    cached_url: string
    _uid: string
    url: string
  }
  text: string // Are these valid?
  aria_label: string // Are these valid?
}

export const localesFieldTranslations = ['en-GB', 'de-DE', 'nl-NL', 'default'] as const

export type LocaleField = (typeof localesFieldTranslations)[number]

export function isLocaleField(field: string): field is LocaleField {
  return localesFieldTranslations.includes(field as LocaleField)
}

export interface StoryblokRequestParameters {
  ['by_slugs']?: string
  ['from_release']?: string
  ['_t']?: string
  ['version']?: 'draft' | 'published'
  ['language']?: string
  ['per_page']?: number
  ['page']?: number
  ['resolve_relations']?: string[]
  ['excluding_fields']?: string
  ['cv']?: number
  ['token']?: string
  ['content_type']?: string
  ['resolve_links']?: 'url'
}

export interface StoryblokDatasourceRequestParameters {
  ['from_release']?: string
  ['_t']?: string
  ['version']?: 'draft' | 'published'
  ['per_page']?: number
  ['page']?: number
  ['cv']?: string
  ['datasource']?: string
  ['dimension']?: string
}

export interface StoryblokSitemapLink {
  slug: string
  published_at?: string
}
