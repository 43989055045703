<script setup lang="ts">
import type { Ratio } from 'types/image'
import { getImageFormat } from 'lib/images'
import { srcSet as fastlySrcset, processImage as processFastly } from 'lib/images/fastly'
import { detectDimensions, processImage as processStoryblok, srcSet as storyblokSrcset } from 'lib/images/storyblok'
import { parseURL } from 'lib/url'

const props = withDefaults(defineProps<{
  src: string
  alt: string
  provider: 'storyblok' | 'fastly'
  lazy?: boolean
  maxWidth?: number
  quality?: number
  columns?: number[]
  ratio?: Ratio
  mobileRatio?: Ratio
  backgroundColor?: string
  objectFit?: 'cover' | 'contain' | 'fill'
  largeAsset?: string
  breakpoint?: number
  height?: string
}>(), {
  lazy: true,
  maxWidth: 3840,
  quality: 60,
  columns: () => [1],
  ratio: undefined,
  mobileRatio: undefined,
  backgroundColor: '',
  objectFit: 'contain',
  largeAsset: undefined,
  breakpoint: 960,
  height: 'auto',
})

const emit = defineEmits(['error', 'load'])
const imgEl = ref<HTMLImageElement>()
const imageURL = computed(() => parseURL(props.src))
const largeImageURL = computed(() => parseURL(props.largeAsset ?? props.src))
const format = computed(() => getImageFormat(imageURL.value))
const useSrcSet = computed(() => props.provider !== 'storyblok' || format?.value !== 'svg')
const imgDimensions = useImageDimensions()

const { srcSet, largeSrcSet } = useSrcSets()
const { srcImg, srcBlurred, srcBlurredLarge } = useImgSrc()
const { onImgLoaded, onImgError } = useImgState()

const sizes = computed(() => {
  const columns = props.columns.length ? props.columns.slice(0, 3) : [1]
  const breakpoints = [0, 960, 1440]
  let sizes = ''
  for (let i = props.columns.length - 1; i > 0; i--)
    sizes += `(min-width: ${breakpoints[i]}px) ${Math.ceil(100 / columns[i]!)}vw, `

  sizes += `${Math.ceil(100 / columns[0]!)}vw`
  return sizes
})

/* Composables */

/** Tries figuring out the image width and height properties to avoid Cumulative Layout Shift (CLS) */
function useImageDimensions(): { width: number, height: number } {
  // Storyblok URLS have width and height within URL
  if (props.provider === 'storyblok') {
    const { width, height } = detectDimensions(imageURL.value)
    if (width > 0 && height > 0)
      return { width, height }
  }

  // Otherwise use aspect-ratio if set
  if (props.ratio && props.ratio !== 'auto') {
    const [width, height] = props.ratio.split(':')
    return { width: Number.parseInt(width), height: Number.parseInt(height) }
  }

  // Otherwise not sure?
  return { width: 0, height: 0 }
}

function useSrcSets() {
  const srcSet = computed(() => {
    switch (true) {
      case props.provider === 'storyblok':
        return storyblokSrcset(imageURL.value, props.quality)
      case props.provider === 'fastly':
        return fastlySrcset(imageURL.value, props.quality, props.maxWidth)
      default:
        return undefined
    }
  })
  const largeSrcSet = computed(() => {
    switch (true) {
      case props.provider === 'storyblok':
        return storyblokSrcset(largeImageURL.value, props.quality)
      case props.provider === 'fastly':
        return fastlySrcset(largeImageURL.value, props.quality, props.maxWidth)
      default:
        return undefined
    }
  })

  return { srcSet, largeSrcSet }
}

function useImgSrc() {
  const backgroundColor = computed(() => {
    return props.backgroundColor.startsWith('#')
      ? props.backgroundColor.slice(1)
      : props.backgroundColor
  })

  const srcImg = computed(() => {
    switch (true) {
      case props.provider === 'storyblok':
        return processStoryblok(imageURL.value, { quality: props.quality, width: 720 })
      case props.provider === 'fastly':
        return processFastly(imageURL.value, {
          width: 720,
          quality: props.quality,
          backgroundColor: backgroundColor.value,
          upscale: false,
        })
      default:
        return undefined
    }
  })

  const srcBlurred = computed(() => {
    switch (true) {
      case props.provider === 'storyblok':
        return processStoryblok(imageURL.value, { quality: '1', width: 90 })
      case props.provider === 'fastly':
        return processFastly(imageURL.value, {
          width: 90,
          quality: '1',
          backgroundColor: backgroundColor.value,
          upscale: false,
        })
      default:
        return undefined
    }
  })

  const srcBlurredLarge = computed(() => {
    switch (true) {
      case props.provider === 'storyblok':
        return processStoryblok(largeImageURL.value, { quality: '1', width: 90 })
      case props.provider === 'fastly':
        return processFastly(largeImageURL.value, {
          width: 90,
          quality: '1',
          backgroundColor: backgroundColor.value,
          upscale: false,
        })
      default:
        return undefined
    }
  })

  return {
    srcImg,
    srcBlurred,
    srcBlurredLarge,
  }
}

function useImgState() {
  const loaded = ref(false)

  function onImgError() {
    emit('error')
  }

  function onImgLoaded() {
    loaded.value = true
    emit('load')
  }

  // @load function not supported on SSR, hack to make it work - https://github.com/nuxt/image/issues/682
  onMounted(() => {
    nextTick(() => {
      if (imgEl.value?.complete)
        imgEl.value.naturalWidth === 0 ? onImgError() : onImgLoaded()
    })
  })

  return {
    imgLoaded: readonly(loaded),
    onImgError,
    onImgLoaded,
  }
}
</script>

<template>
  <div
    class="image-container"
    :class="[{ auto: ratio === 'auto' }]"
    :style="{ backgroundColor }"
  >
    <div
      class="image-container__ratio"
      :data-ratio="ratio"
      :data-mobile-ratio="mobileRatio"
    >
      <!-- Blurred image -->
      <picture aria-hidden="true">
        <source
          v-if="largeAsset"
          :srcset="srcBlurredLarge"
          :media="`(min-width: ${breakpoint}px)`"
        >
        <img
          :loading="lazy ? 'lazy' : 'eager'"
          :src="srcBlurred"
          alt=""
          :width="imgDimensions.width"
          :height="imgDimensions.height"
          :style="{
            opacity: 0.3,
            height: props.height && props.height !== 'auto' ? props.height : undefined,
            objectFit: props.objectFit,
            filter: `blur(10px)`,
          }"
        >
      </picture>

      <!-- Main image -->
      <picture
        class="main"
        :style="{ backgroundColor }"
      >
        <source
          v-if="largeAsset"
          :srcset="largeSrcSet"
          :sizes="sizes"
          :media="`(min-width: ${breakpoint}px)`"
        >
        <source
          v-if="useSrcSet"
          :srcset="srcSet"
          :sizes="sizes"
        >
        <img
          ref="imgEl"
          :loading="lazy ? 'lazy' : 'eager'"
          :src="srcImg"
          :fetchpriority="lazy ? 'auto' : 'high'"
          :alt="alt"
          :width="imgDimensions.width"
          :height="imgDimensions.height"
          :style="{
            height: props.height && props.height !== 'auto' ? props.height : undefined,
            objectFit,
          }"
          @load="onImgLoaded"
          @error="onImgError"
        >
      </picture>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
.image-container {
  position: relative;

  &.auto {
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
  picture {
    width: 100%;
  }

  picture.main {
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    img {
      height:100%;
    }
  }

  &__ratio {
    position: relative;

    &[data-ratio] picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &[data-ratio] img {
      width: 100%;
      height: 100%;
    }

    &[data-ratio='16:9'] {
      padding-top: calc((9 / 16) * 100%);
    }

    &[data-ratio='21:9'] {
      padding-top: calc((9 / 21) * 100%);
    }

    &[data-ratio='4:3'] {
      padding-top: calc((3 / 4) * 100%);
    }

    &[data-ratio='4:5'] {
      padding-top: calc((5 / 4) * 100%);
    }

    &[data-ratio='10:11'] {
      padding-top: calc((11 / 10) * 100%);
    }

    &[data-ratio='10:12'] {
      padding-top: calc((12 / 10) * 100%);
    }

    &[data-ratio='2:5'] {
      padding-top: calc((5 / 2) * 100%);
    }

    &[data-ratio='2:3'] {
      padding-top: calc((3 / 2) * 100%);
    }

    &[data-ratio='1:1'] {
      padding-top: 100%;
    }

    &[data-ratio='auto'] {
      height: 100%;
    }

    &[data-mobile-ratio='16:9'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((9 / 16) * 100%);
      }
    }

    &[data-mobile-ratio='21:9'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((9 / 21) * 100%);
      }
    }

    &[data-mobile-ratio='4:3'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((3 / 4) * 100%);
      }
    }

    &[data-mobile-ratio='4:5'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((5 / 4) * 100%);
      }
    }

    &[data-mobile-ratio='10:11'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((11 / 10) * 100%);
      }
    }

    &[data-mobile-ratio='10:12'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((12 / 10) * 100%);
      }
    }

    &[data-mobile-ratio='2:5'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((5 / 2) * 100%);
      }
    }

    &[data-mobile-ratio='2:3'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: calc((3 / 2) * 100%);
      }
    }

    &[data-mobile-ratio='1:1'] {
      @media (max-width: calc($tablet - 1px)) {
        padding-top: 100%;
      }
    }
  }
}
</style>
