<script setup lang="ts">
import type { HeaderNavbarItem } from '~/types/storyblok/shared-content/navbar'

interface Props {
  items: HeaderNavbarItem[]
}

defineProps<Props>()

const emit = defineEmits(['click'])
</script>

<template>
  <nav
    class="nav-links"
    :aria-label="$t('navigationList')"
  >
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <button
          data-accessibility="nav-link"
          @click="emit('click', item)"
        >
          {{ item.text }}
          <span class="chevron-right" />
        </button>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.nav-links {
  display: block;
  -webkit-overflow-scrolling: touch;
  font-size: 1.6rem;
}

ul {
  display: flex;
  flex-direction: column;
  background-color: var(--gray);
}

li {
  border-bottom: 0.1rem solid var(--divider);
  padding: 1.4rem 1.6rem;
}

button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chevron-right {
  width: 1rem;
  height: 1.5rem;
  background-color: var(--black);
  mask: url('/icons/chevron-right.svg') no-repeat center / contain;
}
</style>
