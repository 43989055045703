import type { Suggestion } from 'types/models/product'

import { defineStore } from 'pinia'

export const useSearchStore = defineStore('SearchStore', () => {
  const loading = ref(false)
  const suggestions = ref<Suggestion[]>([])

  const { $facets } = useNuxtApp()

  const search = async (options: any) => {
    const facetStore = useFacetStore()
    return facetStore.fetch(options)
  }

  const fetchSuggestions = async (query: string) => {
    const mediaStore = useMediaStore()
    try {
      loading.value = true
      const { products } = await $facets.suggestProducts(
        {
          limit: mediaStore.device === 'mobile' ? 4 : 8,
        },
        query,
      )

      if (products)
        suggestions.value = products

      return Promise.resolve({
        products,
      })
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      loading.value = false
    }
  }

  function fetchFacets(options: any) {
    const facetStore = useFacetStore()
    return facetStore.fetchFacetsOnly(options)
  }

  return {
    suggestions,
    loading,

    // Actions
    search,
    fetchFacets,
    fetchSuggestions,
  }
})
