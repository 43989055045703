import type { NuxtSSRContext } from '#app'
import type * as http from 'node:http'
import type { Environment } from 'types/environment'
import axios from 'axios'
import { MARKET_COOKIE } from 'configuration/global.configuration'
import { HALF_A_YEAR_IN_MILLISECONDS } from 'data/time/milliseconds'
import { isValidEnvironmentCountryCode } from 'types/guards/storefront'
import { generateCookieString } from './middleware'

export function setCookie(name: string, value: boolean | string, days: number) {
  const d = new Date()
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${name}=${value};${expires};SameSite=Lax;Path=/`
}

export function parseCookies(cookies: string): { [key: string]: string } {
  try {
    return cookies
      .split(';')
      .map(value => value.split('='))
      .reduce((acc: { [key: string]: string }, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
        return acc
      }, {})
  }
  catch (_) {
    return {}
  }
}

export function getCookie(name: string, cookies = document?.cookie): string | undefined {
  const parsed = parseCookies(cookies)
  return parsed[name]
}

export function hasCookie(name: string, cookies: string = document?.cookie) {
  const parsed = parseCookies(cookies)
  return Object.hasOwnProperty.call(parsed, name)
}

export function deleteCookie(name: string) {
  const exp = new Date()
  exp.setTime(exp.getTime() - 1)
  const cookie = getCookie(name)
  if (!cookie)
    return false

  document.cookie = `${name}=${cookie};expires=${exp.toUTCString()}`
}

export function generateMarketCookieString(countryCode: string) {
  return generateCookieString(MARKET_COOKIE, countryCode.toLowerCase(), {
    Path: '/',
    SameSite: 'Strict',
    Expires: new Date(Date.now() + HALF_A_YEAR_IN_MILLISECONDS).toUTCString(),
  })
}

export function appendCookie(res: http.ServerResponse, cookie: string) {
  const cookies = res.getHeader('Set-Cookie')
  if (!cookies) {
    res.setHeader('Set-Cookie', cookie)
    return
  }

  const cookiesSplit = Array.isArray(cookies) ? cookies : [cookies]
  res.setHeader('Set-Cookie', [...cookiesSplit.map(c => String(c)), cookie])
}

export function parseServerResponseCookies(res: http.ServerResponse) {
  const cookies = res.getHeader('Set-Cookie')
  if (!cookies)
    return {}

  const cookiesSplit = Array.isArray(cookies) ? cookies : [cookies]

  return cookiesSplit.reduce((acc: { [key: string]: string }, cookie) => {
    const [name, value] = String(cookie).split(';')[0].split('=')
    acc[name] = value
    return acc
  }, {})
}

export function getCountryCodeFromCookie(ssrContext?: NuxtSSRContext): Environment.CountryCode | undefined {
  const headers = useRequestHeaders()
  let cookie

  if (process.server && ssrContext) {
    const cookies = parseCookies(headers?.cookie || '')
    cookie
      = parseServerResponseCookies(ssrContext.event.node.res)[MARKET_COOKIE] || cookies[MARKET_COOKIE]
  }

  if (process.client)
    cookie = getCookie(MARKET_COOKIE)

  cookie = cookie?.toUpperCase() || undefined

  if (!cookie)
    return

  return isValidEnvironmentCountryCode(cookie) ? cookie : undefined
}

export async function setMarketCookie(countryCode: Environment.CountryCode, ssrContext?: NuxtSSRContext) {
  if (process.server && ssrContext) {
    appendCookie(ssrContext.event.node.res, generateMarketCookieString(countryCode))
  }
  else {
    try {
      await clientSetMarketCookie(countryCode)
    }
    catch (e) {
      showError({ statusCode: 404, message: `${e}` })
    }
  }
}

export function clientSetMarketCookie(code: Environment.CountryCode) {
  return axios.get(`/api/country-code`, {
    params: { 'country-code': code.toLowerCase(), 'cv': Date.now() },
  })
}
