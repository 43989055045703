import type { StoryblokContent } from '~/types/storyblok'
import type Storyblok from '~/types/vendors/storyblok'

export interface CountryContent extends StoryblokContent {
  component: 'size-guide-country-content'
  content: Storyblok.Table
  country: string
  notes: Storyblok.RichText
}

export interface SizeGuide extends StoryblokContent {
  component: 'pdp-size-guide'
  title: string
  identifier: string
  content_title: string
  size_guide_content: Array<CountryContent>
}

// manipulate result from storyblok to show data as needed
export function normalizeSizeGuides(sizeGuides: Storyblok.Story<SizeGuide>[]): SizeGuide[] {
  return sizeGuides.map((guide) => {
    return guide.content
  })
}
