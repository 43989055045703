import type { ConfigGroup } from '~/data/facets'
import type Storyblok from '~/types/vendors/storyblok'

export interface FacetStory extends Storyblok.Story {
  component: 'facet'
  key: string
  apiId: string
  translationKey?: string
  queryParam?: string
  apiItemsPrefix?: string
}

export interface ConfigGroupWithUid extends ConfigGroup {
  uuid: string
}

export function normalizeFacets(facets: Storyblok.Story<FacetStory>[] | undefined): ConfigGroupWithUid[] {
  const normalizedFacets = facets?.map((facet) => {
    return {
      uuid: facet.uuid,
      key: facet.content.key,
      apiId: facet.content.apiId,
      type: 'facet' as const,
      translationKey: facet.content.translationKey || facet.content.key,
      queryParam: facet.content.queryParam || facet.content.key,
      apiItemsPrefix: facet.content.apiItemsPrefix || facet.content.key,
    }
  })

  return normalizedFacets ?? []
}
