import type { AllMarkets, AllMarketsContent } from '../blocks/all-markets'
import type { StoryblokContent } from '~/types/storyblok'
import type { Story } from '~/types/vendors/storyblok'
import type Storyblok from '~/types/vendors/storyblok'
import { normalizeAllMarkets } from '../blocks/all-markets'

export interface CheckoutFreeShippingBarContent extends StoryblokContent {
  id: Storyblok.Text
  component: 'checkout-free-shipping-bar'
  free_shipping_threshold: AllMarketsContent[]
}

export interface CheckoutFreeShippingBar {
  freeShippingThreshold: AllMarkets
}

export function normalizeCheckoutFreeShippingBar(
  story?: Story<CheckoutFreeShippingBarContent>,
): CheckoutFreeShippingBar | undefined {
  const { content } = story ?? {}

  if (!content)
    return

  return {
    freeShippingThreshold: normalizeAllMarkets(content.free_shipping_threshold[0]),
  }
}
