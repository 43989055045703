
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexZaEsz4mwwdMeta } from "/vercel/path0/pages/[storefront]/[...slugs]/index.vue?macro=true";
import { default as addressKvK7zQvBRKMeta } from "/vercel/path0/pages/[storefront]/checkout/address.vue?macro=true";
import { default as cartwl8oSTIcwdMeta } from "/vercel/path0/pages/[storefront]/checkout/cart.vue?macro=true";
import { default as paymentnd1gGEPWNWMeta } from "/vercel/path0/pages/[storefront]/checkout/payment.vue?macro=true";
import { default as _91_91id_93_932qCA8z7lrwMeta } from "/vercel/path0/pages/[storefront]/checkout/success/[[id]].vue?macro=true";
import { default as checkout4RlX9zT5GaMeta } from "/vercel/path0/pages/[storefront]/checkout.vue?macro=true";
import { default as _91_46_46_46sku_93nFL5fjk60xMeta } from "/vercel/path0/pages/[storefront]/diagnostics/product/[...sku].vue?macro=true";
import { default as _91faq_section_93Mt7D0bKmr9Meta } from "/vercel/path0/pages/[storefront]/faq/[faq_section].vue?macro=true";
import { default as cookie_45policyUgROdmGU7JMeta } from "/vercel/path0/pages/[storefront]/faq/cookie-policy.vue?macro=true";
import { default as faqGzbmMbT5CCMeta } from "/vercel/path0/pages/[storefront]/faq.vue?macro=true";
import { default as indexaRJ8wibFjuMeta } from "/vercel/path0/pages/[storefront]/index.vue?macro=true";
import { default as loginuqP1k1SfTRMeta } from "/vercel/path0/pages/[storefront]/login.vue?macro=true";
import { default as _91sku_93fKmRS3dQBnMeta } from "/vercel/path0/pages/[storefront]/product/[sku].vue?macro=true";
import { default as _91_91pageNumber_93_93Fxq4PFAw9BMeta } from "/vercel/path0/pages/[storefront]/search/[[pageNumber]].vue?macro=true";
import { default as shared_45contentVZ821v38Y1Meta } from "/vercel/path0/pages/[storefront]/shared-content.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: "storefront-slugs",
    path: "/:storefront()/:slugs(.*)*",
    meta: indexZaEsz4mwwdMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/[...slugs]/index.vue")
  },
  {
    name: "storefront-checkout",
    path: "/:storefront()/checkout",
    meta: checkout4RlX9zT5GaMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout.vue"),
    children: [
  {
    name: "storefront-checkout-address",
    path: "address",
    meta: addressKvK7zQvBRKMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/address.vue")
  },
  {
    name: "storefront-checkout-cart",
    path: "cart",
    meta: cartwl8oSTIcwdMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/cart.vue")
  },
  {
    name: "storefront-checkout-payment",
    path: "payment",
    meta: paymentnd1gGEPWNWMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/payment.vue")
  },
  {
    name: "storefront-checkout-success-id",
    path: "success/:id?",
    meta: _91_91id_93_932qCA8z7lrwMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/success/[[id]].vue")
  }
]
  },
  {
    name: "storefront-diagnostics-product-sku",
    path: "/:storefront()/diagnostics/product/:sku(.*)*",
    component: () => import("/vercel/path0/pages/[storefront]/diagnostics/product/[...sku].vue")
  },
  {
    name: "storefront-faq",
    path: "/:storefront()/faq",
    component: () => import("/vercel/path0/pages/[storefront]/faq.vue"),
    children: [
  {
    name: "storefront-faq-faq_section",
    path: ":faq_section()",
    component: () => import("/vercel/path0/pages/[storefront]/faq/[faq_section].vue")
  },
  {
    name: "storefront-faq-cookie-policy",
    path: "cookie-policy",
    component: () => import("/vercel/path0/pages/[storefront]/faq/cookie-policy.vue")
  }
]
  },
  {
    name: "storefront",
    path: "/:storefront()",
    meta: indexaRJ8wibFjuMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/index.vue")
  },
  {
    name: "storefront-login",
    path: "/:storefront()/login",
    component: () => import("/vercel/path0/pages/[storefront]/login.vue")
  },
  {
    name: "storefront-product-sku",
    path: "/:storefront()/product/:sku()",
    meta: _91sku_93fKmRS3dQBnMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/product/[sku].vue")
  },
  {
    name: "storefront-search-pageNumber",
    path: "/:storefront()/search/:pageNumber?",
    meta: _91_91pageNumber_93_93Fxq4PFAw9BMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/search/[[pageNumber]].vue")
  },
  {
    name: "storefront-shared-content",
    path: "/:storefront()/shared-content",
    meta: shared_45contentVZ821v38Y1Meta || {},
    component: () => import("/vercel/path0/pages/[storefront]/shared-content.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  }
]