import type { Link } from '../shared'
import type Storyblok from '~/types/vendors/storyblok'
import type { Category } from '~/utils/categories/normalizeCategories'

export interface SubmenusResponse {
  component: 'submenu'
  _uid: string
  submenu: NavigationBlock[]
  _editable: string
}

export interface SubmenuLink {
  url: string
  text: string
  isHighlighted: boolean
  disabled: boolean
}

export interface Submenu {
  name: string
  links: SubmenuLink[]
}

export interface SubmenuMap {
  [id: string]: Submenu[]
}

export interface NavigationItem {
  _uid: string
  url: (NavigationItemCategory | NavigationLinkTo)[]
}

export interface SublevelNavigationItem extends NavigationItem {
  URL?: (NavigationItemCategory | NavigationLinkTo)[]
  isHighlighted?: boolean
  disabled?: boolean
}

export interface NavigationColumn {
  _uid: string
  name: string
  component: string
  nav_items: SublevelNavigationItem[]
}

export interface ToplevelNavigationItem extends NavigationItem {
  columns: NavigationColumn[]
}

export interface NavigationBlock {
  _uid: string
  link: {
    id: string
    url: string
    linktype: 'url'
    fieldtype: 'multilink'
    cached_url: string
  }
  text: string
  large: boolean
  component: string
  _editable: string
}

export interface NavigationItemCategory {
  category: string
}

export interface NavigationLinkTo {
  link_to: Link
  Label: string
}

export interface HeaderNavbarItem {
  url: string
  text: string
  color?: string
  hide?: boolean
  _uid: string
}

export interface HeaderNavbarResponse {
  _uid: string
  component: 'top-level-nav'
  nav_items: ToplevelNavigationItem[]
}

function isCategoryUrl(url: NavigationItemCategory | NavigationLinkTo): url is NavigationItemCategory {
  return 'category' in url
}

export function normalizeHeaderNavbar(story: Storyblok.Story<HeaderNavbarResponse>, categoryMap: Map<string, Category>): HeaderNavbarItem[] {
  try {
    return story.content.nav_items.map(({ url, _uid }) => {
      const navItemUrl = url[0]
      let slug = ''
      let label = ''

      if (!navItemUrl)
        throw new Error('Undefined item url - incorrect mapping')

      if (isCategoryUrl(navItemUrl)) {
        const category = categoryMap.get(navItemUrl.category as string)

        slug = category?.full_slug ?? ''
        label = category?.content.name ?? ''
      }
      else {
        slug = navItemUrl.link_to.url || navItemUrl.link_to.cached_url
        label = navItemUrl.Label
      }

      return {
        text: label,
        url: slug,
        _uid,
      }
    })
  }
  catch (error) {
    console.error(error)
    throw new Error('Error while normalizing header navbar')
  }
}

export function normalizeSubmenu(submenus: Map<string, NavigationColumn[]>, categoryMap: Map<string, Category>): SubmenuMap {
  try {
    const normalizedSubmenu: SubmenuMap = {}

    for (const [key, value] of submenus.entries()) {
      normalizedSubmenu[key] = value?.map((navItem) => {
        return {
          name: navItem.name,
          links: navItem.nav_items.reduce((acc, curr) => {
            // this parser is because of caching bahaviour in Storyblok - it's only safety check
            const navItemUrl = curr?.URL ? curr.URL?.[0] : curr?.url?.[0]
            let url = ''
            let text = ''

            if (navItemUrl) {
              if (isCategoryUrl(navItemUrl)) {
                const category = categoryMap.get(navItemUrl.category as string)

                url = category?.full_slug ?? ''
                text = category?.content.name ?? ''
              }
              else {
                url = navItemUrl.link_to.url || navItemUrl.link_to.cached_url
                text = navItemUrl.Label
              }
            }

            if (!url || !text)
              return acc

            acc.push({
              url,
              text,
              isHighlighted: Boolean(curr.isHighlighted),
              disabled: Boolean(curr.disabled),
            })

            return acc
          }, [] as SubmenuLink[]),
        }
      })
    }

    return normalizedSubmenu
  }
  catch (error) {
    console.error(error)
    throw new Error('Error while normalizing submenu')
  }
}
