export const useDiscountCodeStore = defineStore('DiscountCodeStore', () => {
  const code = ref('')
  const context = useNuxtApp()

  const cartStore = useCartStore()

  const applyDiscountCode = async (discountCode: string) => {
    try {
      const cart = await context.$cart.applyDiscountCode(discountCode)
      cartStore.updateCart(cart)

      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  const removeDiscountCode = async () => {
    try {
      const cart = await context.$cart.removeDiscountCode()
      cartStore.updateCart(cart)

      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    code,
    applyDiscountCode,
    removeDiscountCode,
  }
})
