import type { CategoryType, FilterGroup, FilterItem } from '../models/filter.model'
import type { XOFacet, XOParameterValue } from '../vendors/xo'
import { convertConfigGroupToFilterGroup, isFilterMenuKey } from '../models/filter.model'

export function queryValueToFilterValue(queryValue: string, valuePrefix: string) {
  return `${valuePrefix}${queryValue}`
}

export function filterValueToQueryValue(filterValue: string, valuePrefix: string) {
  return filterValue.replace(valuePrefix, '')
}

// Normalize XO Facet to our Facet model
export function normalizeXOFacet(facet: XOFacet): FilterGroup | undefined {
  const facetStore = useFacetStore()
  const facetConfigGroup = facetStore.DYNAMIC_FACET_CONFIG.find(facetConfig => facetConfig.apiId === facet.id)

  if (!facetConfigGroup)
    return undefined

  if (!isFilterMenuKey(facetConfigGroup.key))
    return undefined

  const items: FilterItem[] = facet.values.map((value) => {
    const selected = value.selected
    const queryParam = filterValueToQueryValue(value.value, facetConfigGroup.apiItemsPrefix ?? '')

    return {
      id: value.value,
      value: selected,
      disabled: false,
      metadata: {
        queryParam,
        originalId: value.value,
        count: value.count,
      },
    }
  })

  const normalizedGroup = convertConfigGroupToFilterGroup(facetConfigGroup)

  return {
    ...normalizedGroup,
    items,
    metadata: {
      ...normalizedGroup.metadata,
      originalId: facet.id,
      count: facet.count,
    },
  }
}

// Transform SelectableFacets to ParameterValue usable in a XO search query
export function transformFacetToParameterValue(facet: FilterGroup): XOParameterValue {
  const facetStore = useFacetStore()
  const configGroup = facetStore.DYNAMIC_FACET_CONFIG.find(facetConfig => facetConfig.key === facet.id)

  return {
    id: configGroup?.apiId ?? facet.id,
    values: facet.items.filter(item => Boolean(item.value)).map(value => value.id),
  }
}

export function transformCategoryToXOParameterValue(
  category: string,
  type: CategoryType = 'category',
): XOParameterValue {
  const facetStore = useFacetStore()
  const facetKey = type === 'tag' ? 'categoryTag' : 'categoryIds'
  const categoryFacet = facetStore.DYNAMIC_FACET_CONFIG.find(facet => facet.key === facetKey)

  if (!categoryFacet)
    throw new Error('Category facet not found')

  return {
    id: categoryFacet.apiId,
    values: [category],
  }
}

export function transformFacetsToXOParametersValues(
  facets: FilterGroup[],
): XOParameterValue[] {
  return facets.map(transformFacetToParameterValue).filter(facet => facet.values.length > 0)
}

export function normalizeFacets(newFacets: XOFacet[]): FilterGroup[] {
  return newFacets?.reduce((acc, facet) => {
    const normalizedFacet = normalizeXOFacet(facet)

    if (normalizedFacet)
      acc.push(normalizedFacet)

    return acc
  }, [] as FilterGroup[])
}

/**
 * @see https://attraqt.gitbook.io/developer-documentation/xo-search/api-parameters/filtering
 * Take the id of the filterGroup, and the value of the filterItem. Depending on the filterItem you need to select the correct SQL-like filter.
 * price_from = 50 -> price >= 50
 * price_to = 100 -> price <= 100
 */
export function convertFilterGroupsToXOFilters(filters: FilterGroup[]) {
  const mapItemIdToArithmeticOperator: Record<string, string> = {
    price_from: '>=',
    price_to: '<=',
  }

  return filters
    .map(filter => filter.items.filter(item => item.value !== item.metadata?.defaultValue).map((item) => {
      const arithmeticOperator = mapItemIdToArithmeticOperator[item.id] ?? '=='
      /*
       * We need to multiply the selected price range by 100 because the API expects it in that format.
       */
      const value = Number(item.value) ?? 0
      const computedValue = (value: number) => {
        if (item.id === 'price_from' || item.id === 'price_to')
          return value * 100

        return value
      }
      return `${filter.id} ${arithmeticOperator} ${computedValue(value)}`
    }))
    .flat()
}
