import { convertKeyValueListStringToObject } from '../shared/convertKeyValueListStringToObject'
import { convertObjectToKeyValueListString } from '../shared/convertObjectToKeyValueListString'

export function encodeVariables(variables: Record<string, string | string[]>): string {
  return encodeURI(convertObjectToKeyValueListString(variables))
}

export function decodeVariables(variables: string): Record<string, string> {
  return convertKeyValueListStringToObject(variables)
}
