import type { GeneratedStorefrontFile } from 'contracts/generators/storefront-generator-contract'
import { z } from 'zod'

/**
 * This is a generated file, don't touch it!
 *   generated at: 1740769575330
 *   generated from: environment-generation.ts
 */

export type PossibleEnvironment = 'production' |
  'staging'

const storefrontCodes = ['uk', 'de', 'at', 'be', 'ch', 'nl', 'us', 'eu', 'gl'] as const
const warehouseCodes = ['nl-rsv', 'nl', 'us-rsv', 'us'] as const
const warehouseRegions = ['NL', 'US'] as const
const countryCodes = ['GB', 'DE', 'AT', 'BE', 'CH', 'NL', 'US', 'IT', 'HR', 'EE', 'SK', 'SE', 'FI', 'PT', 'LV', 'LT', 'IE', 'CZ', 'SI', 'FR', 'DK', 'ES', 'HU', 'PL', 'LU', 'NO', 'JP', 'AU', 'KR', 'SG', 'IL', 'NZ', 'AE'] as const
const languages = ['en', 'de', 'nl'] as const
const contentLocales = ['en-GB', 'de-DE', 'default'] as const
const contentKeys = ['_default', 'nl', 'uk', 'de', 'eu', 'us', 'gl'] as const
const tenantKeys = ['default', 'UK', 'DE', 'NL', 'US', 'EU', 'GL'] as const
const localeHTML = ['en-GB', 'de-DE', 'de-AT', 'nl-BE', 'de-CH', 'nl-NL', 'en-US', 'en-IT', 'en-HR', 'en-EE', 'en-SK', 'en-SE', 'en-FI', 'en-PT', 'en-LV', 'en-LT', 'en-IE', 'en-CZ', 'en-SI', 'en-FR', 'en-DK', 'en-ES', 'en-HU', 'en-PL', 'en-LU', 'en-NO', 'en-JP', 'en-AU', 'en-KR', 'en-SG', 'en-IL', 'en-NZ', 'en-AE'] as const

export namespace Environment {
  export type StorefrontCode = typeof storefrontCodes[number]
  export type LocaleHTML = typeof localeHTML[number] | string
  export type ContentLocale = typeof contentLocales[number] | string
  export type ContentKey = typeof contentKeys[number]
  export type TenantKey = typeof tenantKeys[number]
  export type Language = typeof languages[number]
  export type CountryCode = typeof countryCodes[number]
  export type WarehouseCode = typeof warehouseCodes[number]
  export type WarehouseRegions = typeof warehouseRegions[number]
  export type Currency = {
    symbol: '£'
    code: 'GBP'
  } | {
    symbol: '€'
    code: 'EUR'
  } | {
    symbol: 'CHF'
    code: 'CHF'
  } | {
    symbol: 'USD'
    code: 'USD'
  } | {
    symbol: 'kr'
    code: 'SEK'
  } | {
    symbol: 'Kč'
    code: 'CZK'
  } | {
    symbol: 'kr'
    code: 'DKK'
  } | {
    symbol: 'kr'
    code: 'NOK'
  } | {
    symbol: '¥'
    code: 'JPY'
  } | {
    symbol: '$'
    code: 'AUD'
  } | {
    symbol: '₩'
    code: 'KRW'
  }
}

// Zod Types
export const zodUnions = {
  storefrontCodes: z.enum(storefrontCodes),
  warehouseCodes: z.enum(warehouseCodes),
  warehouseRegions: z.enum(warehouseRegions),
  countryCodes: z.enum(countryCodes),
  languages: z.enum(languages),
  contentLocales: z.enum(contentLocales),
  contentKeys: z.enum(contentKeys),
  tenantKeys: z.enum(tenantKeys),
  currencies: z.array(z.object({
    symbol: z.string(),
    code: z.string(),
  })),
  localeHTML: z.enum(localeHTML),
}

export const environmentMetadata: Pick<GeneratedStorefrontFile, 'generatedAt' | 'successfulBuilds'> = { generatedAt: 1740769573531, successfulBuilds: { production: true, staging: true } }
