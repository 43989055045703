export function useBackgroundInvertedColor(bgColor: string) {
  const bgInvertedColor = computed(() => {
    if (bgColor === '#0066EE' || bgColor === 'var(--blue)' || bgColor === 'var(--black)' || bgColor === 'black') {
      return 'var(--white)'
    }
    else {
      return 'var(--black)'
    }
  })
  return {
    bgInvertedColor,
  }
}
