<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const route = useRoute()
const cartStore = useCartStore()
const count = computed(() => cartStore.state.itemsCount)
</script>

<template>
  <ClientOnly>
    <NuxtLink
      :to="$checkoutUrl({ route, view: 'cart' })"
      data-test="cart-btn"
      prefetch
      :aria-label="`${$t('showCart')} ${$t('products')} ${count}`"
      v-bind="$attrs"
      @click="$event.stopImmediatePropagation()"
    >
      <IconCart />
    </NuxtLink>
  </ClientOnly>
</template>
