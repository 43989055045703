import type { RawRichtext } from '~/types/storyblok'
import type { NormalizedRichtextFragment, Story } from '~/types/vendors/storyblok'
import { normalizeRichtextDeprecated } from '~/lib/storyblok'

export interface CheckoutNewsletterCheckboxResponse {
  _uid: string
  component: 'checkout-newsletter-checkbox'
  legal_text: RawRichtext
  subscription_cta: string
  _editable: string
}

export interface CheckoutNewsletterCheckbox {
  descriptionCta: string
  legalText?: NormalizedRichtextFragment[]
}

export function normalizeCheckoutNewsletterCheckbox(
  checkoutNewsletterCheckbox: Story<CheckoutNewsletterCheckboxResponse>,
): CheckoutNewsletterCheckbox {
  const {
    content,
    content: { legal_text },
  } = checkoutNewsletterCheckbox

  const normalizedLegalText = normalizeRichtextDeprecated(legal_text)

  return {
    descriptionCta: content.subscription_cta?.trim() || '',
    legalText: normalizedLegalText,
  }
}
