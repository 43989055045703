import type { StoryblokContent } from '../../storyblok'
import type { ConfigGroupWithUid } from './facets'
import type Storyblok from '~/types/vendors/storyblok'

export interface SidebarFilters extends StoryblokContent {
  component: 'sidebar-filters'
  facets: string[]
}

export function normalizesidebarFilters(story: Storyblok.Story<SidebarFilters>, facets: ConfigGroupWithUid[]) {
  return facets.filter((facet) => {
    return story.content.facets.includes(facet.uuid)
  }).toSorted((a, b) => {
    return story.content.facets.indexOf(a.uuid) - story.content.facets.indexOf(b.uuid)
  })
}
