<script setup lang="ts">
const props = defineProps<{
  isLogoCollapsed: boolean
  device: 'desktop' | 'mobile'
}>()
const emit = defineEmits(['logoCollapse'])
const mounted = useMounted()

/* Logo indentation */
const { isCartEmpty } = storeToRefs(useCartStore())
const isLogoIndented = computed(() => mounted.value && props.device === 'mobile' && !isCartEmpty.value)

/* Logo collapse listeners */
const events = ['click', 'mousemove', 'keydown', 'touchstart', 'scroll']
let logoCollapseTimeout: ReturnType<typeof setTimeout>
function addListeners() {
  if (props.isLogoCollapsed)
    return

  events.forEach(event => document.addEventListener(event, handleLogoCollapse))
  logoCollapseTimeout = setTimeout(() => handleLogoCollapse(), 4000)
}
function removeListeners() {
  events.forEach(event => document.removeEventListener(event, handleLogoCollapse))
  clearTimeout(logoCollapseTimeout)
}
function handleLogoCollapse() {
  emit('logoCollapse')
  removeListeners()
}
onMounted(() => addListeners())
onUnmounted(() => removeListeners())
</script>

<template>
  <div
    class="logo"
    :class="[
      {
        'logo--collapsed': isLogoCollapsed,
        'logo--indent': isLogoIndented,
      },
    ]"
  >
    <NuxtLink
      class="logo__inner"
      :to="$url('/')"
      aria-label="Happy Socks Logo"
    >
      <img
        class="logo__icon"
        src="/icons/logo-hs.svg"
        alt="Happy Socks Logo"
        width="26"
        height="24"
      >
      <span class="logo__text">
        <img
          class="logo__text__content"
          src="/icons/logo-hs-large.svg"
          alt="Happy Socks Full Logo"
          width="122"
          height="12"
        >
      </span>
    </NuxtLink>
    <div aria-live="polite">
      <HeaderSpeechBubble :device="device" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  $logo: &;
  position: relative;

  &--indent {
    transition: transform 0.5s ease-out;
    transform: translateX(23px);
  }

  &__inner {
    height: var(--spacing-l);
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__icon {
    margin-right: 0.5rem;
    transition: margin-right 0.5s ease-out;

    #{$logo}--collapsed & {
      margin-right: 0;
    }
  }

  &__text {
    overflow: hidden;
    height: 1.2rem;
    display: flex;
    max-width: 12.9rem;
    transition: max-width 0.5s ease-out;

    #{$logo}--collapsed & {
      max-width: 0;
    }

    &__content {
      transform: translateX(0);
      transition: transform 0.5s ease-out, margin-left 0.5s ease-out;
      margin-left: 0.7rem;
      max-width: unset;

      #{$logo}--collapsed & {
        transform: translateX(-20rem);
        margin-left: 0;
      }
    }
  }
}
</style>
