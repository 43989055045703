import type { Storefront } from '~/types/models/storefront'
import type { SharedContentNormalized, SharedContentResponse } from '~/types/storyblok/shared-content'
import type { NavigationColumn } from '~/types/storyblok/shared-content/navbar'
import type { Category } from '~/utils/categories/normalizeCategories'
import { getSortedStories } from '~/lib/content/getSortedStories'
import { normalizeCheckoutFreeShippingBar } from '~/types/storyblok/shared-content/checkout-free-shipping-bar'
import { normalizeFacets } from '~/types/storyblok/shared-content/facets'
import { normalizeHeaderNavbar, normalizeSubmenu } from '~/types/storyblok/shared-content/navbar'
import { normalizeNewsletter } from '~/types/storyblok/shared-content/newsletter'
import { normalizeCheckoutNewsletterCheckbox } from '~/types/storyblok/shared-content/newsletter-checkbox'
import { normalizePdpEnhancedBlocks } from '~/types/storyblok/shared-content/pdp-enhanced'
import { normalizePdpFeaturesIcons } from '~/types/storyblok/shared-content/pdp-features-icons'
import { normalizeSegmentStories } from '~/types/storyblok/shared-content/segment'
import { normalizeSegmentedUpsell } from '~/types/storyblok/shared-content/segmented-upsell'
import { normalizesidebarFilters } from '~/types/storyblok/shared-content/sidebar-filters'
import { normalizeSizeGuides } from '~/types/storyblok/shared-content/size-guides'

import { normalizeSpeechBubble } from '~/types/storyblok/shared-content/speech-bubble'
import { normalizeWidgets } from '~/types/storyblok/shared-content/widgets'
import { normalizeCategory } from '~/utils/categories/normalizeCategories'
import { getSharedContentComponentsFromMultipleStoryResponse } from '../shared-content'
import { normalizeSegmentedPopup } from './../../../types/storyblok/shared-content/segmented-popup'

export async function normalizeSharedContent(storefront: Storefront, stories: SharedContentResponse['stories'], categories: Category[]): Promise<SharedContentNormalized> {
  const categoryMap = new Map<string, Category>()

  for (const current of categories) {
    const { uuid } = current
    const category = normalizeCategory(current)

    if (category) {
      categoryMap.set(uuid, category)
    }
  }

  const storiesSortedByFullSlug = getSortedStories(
    stories,
    storefront.contentKey,
    storefront.storefrontCode,
  )

  const sharedContentComponents
    = getSharedContentComponentsFromMultipleStoryResponse(storiesSortedByFullSlug)

  const submenus = new Map<string, NavigationColumn[]>()
  const submenusAlternate = new Map<string, NavigationColumn[]>()

  for (const current of sharedContentComponents.headerNavbar?.content?.nav_items ?? []) {
    const { _uid, columns } = current
    submenus.set(_uid, columns)
  }

  for (const current of sharedContentComponents.headerNavbarAlternate?.content?.nav_items ?? []) {
    const { _uid, columns } = current
    submenusAlternate.set(_uid, columns)
  }
  const facets = normalizeFacets(sharedContentComponents.facets)
  const widgets = normalizeWidgets(sharedContentComponents.widgets, {
    tenantKey: storefront.tenantKey,
  })

  return Promise.resolve({
    categories: categoryMap,
    checkoutFreeShippingBar: normalizeCheckoutFreeShippingBar(sharedContentComponents.checkoutFreeShippingBar),
    segmentedUpsellStories: normalizeSegmentedUpsell(sharedContentComponents.segmentedUpsellStories, widgets),
    sizeGuides: normalizeSizeGuides(sharedContentComponents.sizeGuides),
    pdpEnhancedBlocks: normalizePdpEnhancedBlocks(sharedContentComponents.pdpEnhancedBlocks),
    speechBubbleStates: normalizeSpeechBubble(
      sharedContentComponents.speechBubbleStates,
    ),
    submenus: normalizeSubmenu(submenus, categoryMap),
    submenusAlternate: normalizeSubmenu(submenusAlternate, categoryMap),
    newsletter:
        sharedContentComponents.newsletter
        && normalizeNewsletter(sharedContentComponents.newsletter),
    checkoutNewsletterCheckbox:
        sharedContentComponents.checkoutNewsletterCheckbox
        && normalizeCheckoutNewsletterCheckbox(
          sharedContentComponents.checkoutNewsletterCheckbox,
        ),
    segmentedPopups: normalizeSegmentedPopup(sharedContentComponents.segmentedPopups),
    topBanner: sharedContentComponents.topBanner?.content,
    headerNavbar:
        sharedContentComponents.headerNavbar
        && normalizeHeaderNavbar(
          sharedContentComponents.headerNavbar,
          categoryMap,
        ),
    headerNavbarAlternate:
      sharedContentComponents.headerNavbarAlternate
      && normalizeHeaderNavbar(
        sharedContentComponents.headerNavbarAlternate,
        categoryMap,
      ),
    checkoutBanner:
        sharedContentComponents.checkoutBanner
        && sharedContentComponents.checkoutBanner.content.message.trim(),
    pdpFeaturesIcons:
        sharedContentComponents.pdpFeaturesIcons
        && normalizePdpFeaturesIcons(sharedContentComponents.pdpFeaturesIcons),
    pdpCertifications: sharedContentComponents.pdpCertifications?.content?.certifications,
    pdpShippingAndReturns: sharedContentComponents.pdpShippingAndReturns?.content,
    pdpSustainability: sharedContentComponents.pdpSustainability?.content,
    widgets,
    footer: sharedContentComponents.footer?.content,
    facets,
    sidebarFilters:
    sharedContentComponents.sidebarFilters
    && normalizesidebarFilters(
      sharedContentComponents.sidebarFilters,
      facets,
    ),
    segments: sharedContentComponents.segments && normalizeSegmentStories(sharedContentComponents.segments),
  })
}
